// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GHyesVaZ{padding:0 0 40px}@media(min-width:667.98px){.GHyesVaZ{padding:0 0 100px}}.di63p-Og{margin-bottom:1.25rem}@media(min-width:667.98px){.di63p-Og{margin-bottom:3.75rem}}.n-b2Ncu5{background-repeat:no-repeat;background-size:cover;border-radius:20px;display:flex;flex-direction:column;height:80vw;max-height:480px;max-width:100%;padding:16px;transition:transform .15s ease-in-out;width:inherit}@media(hover:hover){.n-b2Ncu5:hover{transform:scale(.98)}}@media(hover:none){.n-b2Ncu5:active{transform:scale(.98)}}@media(min-width:667.98px){.n-b2Ncu5{height:45vw;padding:50px}}@media(min-width:947.98px){.n-b2Ncu5{height:32vw}}.UgwGwSS1{display:flex;font-size:14px;justify-content:space-between;padding:0 0 16px}@media(min-width:947.98px){.UgwGwSS1{font-size:24px;padding:0 0 30px}}.YTzNePYp{color:var(--color-text);opacity:.5}.AbmVKAty{-webkit-text-fill-color:transparent;-webkit-background-clip:text;background-clip:text;background-image:linear-gradient(to right,var(--gradient-from),var(--gradient-to))}.tVvQFywy{flex-grow:1;font-size:24px;font-weight:400;margin:0}@media(min-width:947.98px){.tVvQFywy{font-size:30px}}.yWDz7BvO{font-weight:400}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "GHyesVaZ",
	"halls": "di63p-Og",
	"hall": "n-b2Ncu5",
	"header": "UgwGwSS1",
	"headerDate": "YTzNePYp",
	"headerTime": "AbmVKAty",
	"title": "tVvQFywy",
	"footer": "yWDz7BvO"
};
module.exports = ___CSS_LOADER_EXPORT___;
